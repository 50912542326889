<template>
    <div :class="{ 'flex-space': field.type === 'checkbox' }">
        <label v-if="label" :for="field.id" class="form-label" :class="{ 'text-bold': bold }">
            <span>{{ label }}</span>
            <span v-if="required" class="text-error ml-1">*</span>
        </label>

        <textarea v-if="field.type === 'text'" v-model="value" :placeholder="label" :id="field.id" class="form-input"
            maxlength="4000" rows="3" :required="required"></textarea>

        <label v-if="field.type === 'checkbox'" class="form-checkbox">
            <input type="checkbox" v-model="value" :id="field.id" :true-value="1" :false-value="0"
                :required="required" />
            <i class="form-icon"></i>
        </label>

        <MultiSelect v-else-if="field.type === 'single_select'" v-model="value" :options="field.options.choices"
            :placeholder="label" :inputId="(field.id || '').toString()" :required="required"
            :multiple="field.options.multi">
        </MultiSelect>

        <input v-else-if="field.type === 'number'" type="number" v-model.number="value" :id="field.id"
            :placeholder="label" class="form-input" :step="1 / 10 ** field.options.numdecimals" :required="required" />

        <template v-else-if="field.type === 'date'">
            <DatePicker v-if="field.options.mode === 'date'" v-model="value" format="yyyy-MM-dd" model-type="yyyy-MM-dd"
                :placeholder="label" :required="required">
            </DatePicker>
            <DatePicker v-else-if="field.options.mode === 'datetime'" v-model="value" format="yyyy-MM-dd HH:mm"
                model-type="yyyy-MM-dd HH:mm" time-picker-inline :enable-time-picker="true" :placeholder="label"
                :required="required">
            </DatePicker>
            <input v-else-if="field.options.mode === 'time'" v-model="value" v-maska="'##:##'" class="form-input"
                placeholder="12:34" inputmode="numeric" :id="field.id" :required="required">
        </template>
    </div>
</template>

<script>
import { vMaska } from 'maska/vue'

export default {
    props: {
        field: Object,
        label: String,
        bold: Boolean,
        required: Boolean,
        modelValue: [String, Number, Array, Date],
    },

    emits: ['update:modelValue'],
    directives: { maska: vMaska },

    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    },
}
</script>
