<template>
    <WatchForUnsavedChanges ref="watch" v-model="tag" v-slot="{ hasChanges }">
        <Spinner v-if="id && !tag.id"></Spinner>
        <SidePanel v-else :title="`${id ? $t('fields.field') : $t('context.create_fields')}`" @close="close()">
            <div class="form-group">
                <Errors :errors="errors" />
            </div>

            <div class="form-group">
                <label class="form-label" for="name">{{ $t('fields.field') }}</label>
                <input id="name" class="form-input" v-model="tag.name" :placeholder="$t('fields.field')" />
            </div>

            <div class="form-group" v-if="!id">
                <label class="form-label" for="type">{{ $t('fields.type') }}</label>
                <select v-model="tag.type" id="type" class="form-select">
                    <option v-for="t in types" :value="t">{{ $t(`fields.${t}`) }}</option>
                </select>
            </div>

            <div class="form-group" v-if="tag.type === 'select' || tag.type === 'sharing'">
                <div class="flex-space mb-1" v-for="(_, i) in tag.properties[tag.type]">
                    <input class="form-input mr-2" v-model="tag.properties[tag.type][i]"
                        :placeholder="i + 1 + '.'" />
                    <button class="btn btn-sm btn-clear mr-2" @click="removeOption(i, tag.type)"></button>
                </div>

                <button class="btn btn-sm" @click="addOption(tag.type)">
                    <i class="far fa-plus mr-2"></i>
                    <span>{{ $t('forms.add_option') }}</span>
                </button>
            </div>

            <div class="form-group" v-else-if="tag.type === 'geo_fencing'">
                <button class="btn my-1" @click="geoModal = true">
                    <i class="fas fa-cog mr-2"></i>
                    <span>{{ $t('fields.geofence_options') }}</span>
                </button>

                <ul v-for="fence in tag.properties.geo_fencing">
                    <li>{{ fence.name }}</li>
                </ul>
            </div>

            <div class="form-group" v-else-if="tag.type === 'seal'">
                <label class="form-label" for="regex">
                    {{ $t('regex.regular_expression') }}
                </label>
                <input id="regex" class="form-input" v-model="tag.properties.seal.regex"
                    placeholder="[A-Z]{1,8}\d{1,12}" />
            </div>

            <div class="form-group" v-if="tag.type === 'checkbox' || tag.type === 'select'">
                <label class="form-switch">
                    <input type="checkbox" v-model="tag.auto_tag" />
                    <i class="form-icon"></i>
                    <span>{{ $t('fields.add_to_new_files') }}</span>
                </label>
            </div>

            <div class="form-group">
                <label class="form-label" for="color">{{ $t('fields.color') }}</label>
                <input type="color" v-model="tag.color" id="color" class="form-input" />
            </div>

            <div class="form-group">
                <label class="form-label" for="location_id">{{ $t('fields.available_for_locations') }}</label>
                <select v-model="tag.location_id" id="location_id" class="form-select">
                    <option :value="null">{{ $t('fields.all_locations') }}</option>
                    <option v-for="location in locations" :value="location.id">{{ location.location }}</option>
                </select>
            </div>

            <div class="flex-space">
                <SaveButton @click="save()" class="mr-auto" :disabled="!hasChanges" />
                <button v-if="id" class="btn btn-error" @click="removeTag()">{{ $t('main.delete') }}</button>
            </div>

            <GeofenceModal v-if="geoModal" :data="tag.properties.geo_fencing" @close="geoModal = false"
                @save="saveGeofenceSettings"></GeofenceModal>
        </SidePanel>
    </WatchForUnsavedChanges>
</template>

<script>
import SidePanel from '~/components/SidePanel.vue'
import GeofenceModal from './FieldsCreateGeofenceModal.vue'

export default {
    props: {
        onClose: Function,
        types: {
            type: Array,
            default() {
                return ['text', 'number', 'date', 'checkbox', 'select', 'geo_fencing', 'sharing', 'seal']
            },
        },
    },

    emits: ['refresh'],
    components: { SidePanel, GeofenceModal },

    data() {
        return {
            errors: null,
            locations: [],
            geoModal: false,
            id: this.$route.params.field_id,
            tag: {
                auto_tag: false,
                location_id: null,
                type: this.types[0],
                color: '#ffffff',
                name: '',
                properties: {
                    select: [''],
                    sharing: [''],
                    seal: { regex: '' },
                    geo_fencing: [],
                },
            },
        }
    },

    async mounted() {
        if (this.id) await this.fetchData()
        this.fetchLocations()

        this.$refs.watch?.init()
    },

    methods: {
        async fetchData() {
            const { data } = await this.$axios.get(`fields/${this.id}`)
            this.tag = data
        },

        async fetchLocations() {
            const { data: paginator } = await this.$axios.get('locations', {
                params: { sort: 'location', by: 'asc', limit: 200 },
            })

            this.locations = paginator.data
        },

        close() {
            if (this.onClose) {
                return this.onClose()
            }

            this.$router.push({ name: 'settings.fields' })
        },

        async save() {
            this.errors = null
            const tag = this.tag

            if (tag.type === 'seal') {
                if (tag.properties.seal.regex) {
                    try {
                        new RegExp(tag.properties.seal.regex)
                    } catch {
                        return alert('Regular expression pattern error')
                    }
                } else {
                    tag.properties.seal.regex = '[A-Z]{0,8}\\d{4,12}'
                }
            }

            if (tag.properties) {
                // only store what is necessary per type
                tag.properties = { [tag.type]: tag.properties[tag.type] }
            }

            try {
                const { data } = await this.$axios({
                    method: this.id ? 'patch' : 'post',
                    url: this.id ? `fields/${this.id}` : 'fields',
                    data: tag,
                })

                window.toast(this.$root.$t(this.id ? 'i.update' : 'i.create', { i: tag.name }))
                this.$refs.watch?.init()

                if (this.onClose) {
                    this.onClose(data)
                } else {
                    this.$emit('refresh')
                    this.$router.push({ name: 'settings.fields' })
                }
            } catch ({ response }) {
                this.errors = response
            }
        },

        removeTag() {
            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (result.value) {
                    this.$axios.delete(`fields/${this.id}`).then(() => {
                        this.$emit('refresh')
                        this.$refs.watch?.init()
                        this.$router.push({ name: 'settings.fields' })
                    })
                }
            })
        },

        addOption(type) {
            if (this.tag.properties[type].length < 50) {
                this.tag.properties[type].push('')
            }
        },

        removeOption(index, type) {
            if (this.tag.properties[type].length > 1) {
                this.tag.properties[type].splice(index, 1)
            }
        },

        saveGeofenceSettings(data) {
            this.tag.properties.geo_fencing = data
            this.geoModal = false
        },
    },
}
</script>
