<template>
    <div class="text-editor">
        <div class="wysiwyg" :class="{ ghost: loading }">
            <QuillyEditor v-model="value" :options="options" @keyup.ctrl.enter="save()" @keyup.esc="cancel()"
                ref="editor" :disabled="true">
            </QuillyEditor>
        </div>

        <div class="toolbar-crud">
            <button v-if="onDelete" class="btn btn-sm mr-2" @click="remove()">
                <i class="fas fa-trash"></i>
            </button>

            <button v-if="onSave" class="btn btn-sm btn-success" @click="save()" :disabled="isEmpty"
                :class="{ loading }">
                <span>{{ buttonText || $t('main.save') }}</span>
            </button>

            <button v-if="onCancel" class="btn btn-sm ml-2" @click="cancel()">{{ $t('main.cancel') }}</button>
        </div>
    </div>
</template>

<script>
import Quill from 'quill'
import { QuillyEditor } from 'vue-quilly'
import 'quill/dist/quill.snow.css';

export default {
    props: {
        modelValue: String,
        placeholder: String,
        buttonText: String,
        autoFocus: Boolean,
        onCancel: Function,
        onDelete: Function,
        onSave: Function,
    },

    components: { QuillyEditor },
    emits: ['update:modelValue'],

    data() {
        return {
            loading: false,
            originalValue: this.modelValue,
            options: {
                theme: 'snow',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                    ]
                },
                placeholder: this.placeholder
            }
        }
    },

    mounted() {
        const quill = this.$refs.editor?.initialize(Quill)

        if (this.autoFocus) quill.setSelection(quill.getLength(), 0)

        document.querySelectorAll('.ql-toolbar button').forEach(btn => btn.tabIndex = -1)
    },

    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },

        isEmpty() {
            if (!this.modelValue) return true
            if (this.modelValue === '<p><br></p>') return true
        },
    },

    methods: {
        async save() {
            if (!this.onSave || this.loading) return

            this.loading = true

            try {
                await this.onSave()
            } catch (error) {
                window.toast(error.response?.data?.message, 'error')
            }

            this.loading = false
        },

        cancel() {
            if (!this.onCancel) return

            this.value = this.originalValue
            this.$emit('cancel')
        },

        remove() {
            if (!this.onDelete) return

            this.$emit('delete')
        }
    }
}
</script>