<template>
    <VueDatePicker v-model="value" :locale="locale" :format="format" :enable-time-picker="false"
        :ui="{ input: 'form-input' }" month-name-format="long" :month-change-on-scroll="false" auto-apply
        :dark="$store.state.darkMode">
        <template #clear-icon="{ clear }">
            <CloseIcon @click="clear" class="p-2 mr-2" />
        </template>
    </VueDatePicker>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker'
import CloseIcon from './icons/CloseIcon.vue'

export default {
    props: ['modelValue'],
    emits: ['update:modelValue'],
    components: { VueDatePicker, CloseIcon },

    computed: {
        locale() {
            const locale = this.$i18n.locale
            if (locale === 'br') return 'pt-br'
            return locale
        },

        value: {
            get() {
                return this.modelValue
            },
            set(date) {
                // for dateranges, we typically search between dates
                // the following makes sure we search on the entire day of the start range
                if (Array.isArray(date)) {
                    const range1 = dayjs(date[0]).hour(0).minute(0).second(0).toISOString()
                    const range2 = dayjs(date[1]).hour(0).minute(0).second(0).toISOString()

                    date = [range1, range2]
                }

                this.$emit('update:modelValue', date)
            }
        }
    },

    methods: {
        format(date) {
            if (Array.isArray(date)) {
                return date.map(v => dayjs(v).format('L')).join(' ~ ')
            }

            return dayjs(date).format('L')
        }
    }
}
</script>