<template>
    <Dropdown class="field-search-dropdown dropdown-right dropdown-big" :badge="searchFieldsEnabledCount"
        @mouseover.once="fetchData()">
        <template #toggle>
            <i class="far fa-fw fa-tags mr-2"></i>
            <span>{{ $t('files.search_on_fields') }}</span>
        </template>
        <div class="nav">
            <select ref="search" class="form-select" v-model="newSearch.field" @change="newSearch.operator = '='">
                <option :value="null" disabled hidden>{{ $t('files.search_on_fields') }}</option>
                <option v-for="field in fields" :value="field">{{ field.name }}</option>
            </select>
            <template v-if="newSearch.field">
                <div v-if="operatorsForType.length > 1">
                    <button v-for="operator in operatorsForType" @click="newSearch.operator = operator" class="btn mr-2"
                        :class="{ 'btn-primary': operator === newSearch.operator }">
                        {{ $t(`files.operator_${operator}`) }}
                    </button>
                </div>

                <div>
                    <FieldsModel :field="newSearch.field" v-model="newSearch.value"></FieldsModel>
                    <button @click="saveSearch()" class="btn btn-primary ml-2">{{ $t('main.search') }}</button>
                </div>
            </template>

            <div v-if="searchFields.length" class="field-searches">
                <div class="divider text-center"
                    :data-content="$t('table.field_searches_active', searchFieldsEnabledCount)">
                </div>

                <div class="fields-searches-list">
                    <div class="flex-space" v-for="search in searchFields">
                        <label class="form-checkbox">
                            <input type="checkbox" v-model="search.enabled" />
                            <i class="form-icon"></i>
                        </label>
                        <div class="mr-auto">
                            <span class="mr-1">{{ search.name }}</span>
                            <span class="text-lowercase">{{ $t(`files.operator_${search.operator}`) }}</span>
                            <span class="ml-1">'{{ search.value }}'</span>
                        </div>
                        <button class="btn btn-sm btn-clear" @click="removeSearch(search)"></button>
                    </div>
                </div>
            </div>
        </div>
    </Dropdown>
</template>

<script>
import FieldsModel from '~/components/files/FileFieldsModel.vue'

export default {
    props: {
        searchFields: {
            type: Array,
            required: true,
        },
    },

    components: { FieldsModel },

    data() {
        return {
            fields: [],
            newSearch: this.getDefault(),
        }
    },

    mounted() {
        // Prefetch data on mobile
        if (window.innerWidth <= 1280) this.fetchData()
    },

    computed: {
        searchFieldsEnabledCount() {
            return this.searchFields.filter(field => field.enabled).length
        },

        operatorsForType() {
            const type = this.newSearch.field.type

            if (type === 'number') {
                return ['<', '=', '>']
            } else if (type === 'text') {
                return ['=', 'contains']
            } else {
                return ['=']
            }
        },
    },

    watch: {
        searchFields: {
            deep: true,
            handler(fields) {
                // Save field searches to vuex store
                this.$store.commit('UPDATE_FILES_SEARCH_FIELDS', fields)
            },
        },
    },

    methods: {
        async fetchData() {
            const { data: paginator } = await this.$axios.get('fields', {
                params: { limit: 200, sort: 'name', by: 'asc' },
            })

            this.fields = paginator.data
        },

        getDefault() {
            return {
                field: null,
                value: null,
                enabled: true,
                operator: null,
            }
        },

        saveSearch() {
            const search = this.newSearch

            if (search.field.type === 'checkbox') {
                search.value = search.value ? '1' : '0'
            }

            if (!search.value) return

            this.searchFields.unshift({
                value: search.value,
                id: search.field.id,
                name: search.field.name,
                operator: search.operator,
                enabled: search.enabled,
            })

            this.newSearch = this.getDefault()
        },

        removeSearch(search) {
            const index = this.searchFields.indexOf(search)
            this.searchFields.splice(index, 1)
        },
    },
}
</script>
