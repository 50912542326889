<template>
    <div>
        <ContextBar :title="$t('main.workflows')" backRoute="workflows">
            <DebounceInput v-model="search" :placeholder="$t('files.search_on_reference')" clearable searchIcon />
        </ContextBar>

        <DataTable :endpoint="`workflows/${id}/runs`" :columns="columns" :parameters="parameters"
            sort="submit_date_time" :key="refresh" hasFilters>
            <template #context-left v-if="workflow.id">
                <h2>{{ workflow.name }}</h2>
                <Dropdown class="dropdown-right ml-auto" v-if="$store.getters.can('Use global settings')">
                    <div class="menu-item">
                        <router-link :to="{ name: 'workflows.edit', params: { id: workflow.id } }">
                            {{ $t('main.edit') }}
                        </router-link>
                    </div>
                    <div class="menu-item">
                        <a href="#" @click.prevent="duplicateWorkflow(workflow)">{{ $t('main.duplicate') }}</a>
                    </div>
                    <div class="menu-item">
                        <a href="#" class="text-error" @click.prevent="deleteWorkflow(workflow)">
                            {{ $t('main.delete') }}
                        </a>
                    </div>
                </Dropdown>
            </template>
            <template #context-right>
                <DatePicker v-model="date_range" :placeholder="$t('files.created_between')" range multi-calendars>
                </DatePicker>
            </template>

            <template #column(scan_code)="{ item }">
                <FileReference :id="item.file_id" :reference="item.scan_code"></FileReference>
            </template>
            <template #column(submit_date_time)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
            <template #column(updated_at)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
            <template #column(completed_at)="{ item }">
                <div v-if="item.completed_at" class="chip">
                    <div class="mr-1">{{ $t('workflows.completed') }}</div>
                    <DateTime :date="item.completed_at" ago></DateTime>
                </div>
                <div v-else class="chip">
                    {{ item.workflow_run_steps_count }} / {{ workflowStepsCount }} {{ $t('workflows.steps') }}
                </div>
            </template>
        </DataTable>

        <router-view @refresh="refresh++"></router-view>
    </div>
</template>

<script>
import DataTable from '~/components/DataTable.vue'
import FileReference from '~/components/FileReference.vue'
import { duplicateWorkflow, deleteWorkflow } from '~/utils/workflows.js'

export default {
    components: { DataTable, FileReference },

    data() {
        return {
            refresh: 0,
            workflow: {},
            id: this.$route.params.id,
            search: this.$route.query.reference,
            date_range: this.$route.query.date_range,

            columns: [
                { name: 'scan_code', th: 'files.reference', sortable: true },
                { name: 'submit_date_time', th: 'main.created', sortable: true },
                { name: 'updated_at', th: 'main.updated', sortable: true },
                { name: 'completed_at', th: 'reports.status', sortable: true },
            ],
        }
    },

    computed: {
        parameters() {
            return {
                search: this.search || undefined,
                date_range: this.date_range || undefined,
            }
        },

        workflowStepsCount() {
            if (!this.workflow.steps) return
            return this.workflow.steps.length
        },
    },

    async mounted() {
        const { data: workflow } = await this.$axios.get(`workflows/${this.id}`)

        this.workflow = workflow
    },

    methods: {
        async deleteWorkflow(w) {
            await deleteWorkflow(w, this.$swal)

            window.toast(this.$root.$t('i.delete', { i: w.title }))

            this.$router.push({ name: 'workflows' })
        },

        async duplicateWorkflow(w) {
            const { data: workflow } = await duplicateWorkflow(w, this.$swal)

            window.toast(this.$root.$t('i.create', { i: workflow.name }))
            this.$router.push({ name: 'workflows.edit', params: { id: workflow.id } })
        },
    },
}
</script>
