<template>
    <div class="issue-description box text-large">
        <TextEditor v-if="issue.edit" v-model="issue.description" autoFocus @save="updateDescription(issue)"
            @cancel="issue.edit = false" :placeholder="$t('issues.write_a_comment')">
        </TextEditor>

        <div v-else>
            <div class="ql-editor" v-html="issue.description"></div>
            <i @click="issue.edit = true" class="far fa-pencil c-hand"></i>
        </div>
    </div>
</template>

<script>
import IssueTimelineItem from './IssueTimelineItem.vue'
import TextEditor from '~/components/htmlform/TextEditor.vue'

export default {
    emits: ['update'],
    props: { issue: Object },
    components: { IssueTimelineItem, TextEditor },

    data() {
        return {
            issue_number: this.$route.params.issue_number,
        }
    },

    methods: {
        async updateDescription(issue) {
            await this.$axios.patch(`issues/${this.issue_number}`, { description: issue.description })
            issue.edit = false
            this.$emit('update')
        },
    }
}
</script>