<template>
    <div class="dark-heading">
        <Spinner v-if="loading"></Spinner>

        <FileShow v-else-if="file.id" :file="file" backRoute="files" @editReference="editReference()"
            @toggleClosed="file.closed_at ? openFile() : closeFile()" @refresh="fetchData()" :class="{
            'can-edit': canEdit,
            'can-open': canOpen,
            'can-close': canClose,
            'can-delete': canDelete,
            'can-send-images': canSendImages,
            'can-create-snap': canCreateSnap,
            'can-create-submit': canCreateSubmit,
            'can-edit-snap': canEditSnaps,
            'can-edit-submit': canEditSubmits,
            'can-delete-snap': canDeleteSnaps,
            'can-delete-submit': canDeleteSubmits,
            'can-share-file': canShareFile,
            'can-view-history': canViewHistory,
            'can-create-field': canCreateField,
            'can-create-report': canCreateReport,
            'can-download-images': canDownloadImages,
            'can-create-issues': canCreateIssues,
        }">
            <template #actions>
                <router-link tag="button" class="btn send-images hide-xl" :to="{ name: 'files.send_images' }">
                    <i class="fas fa-paper-plane mr-2"></i>
                    <span>{{ $t('files.send_by_email') }}</span>
                    <small v-if="file.images_sent" class="label label-success">
                        <DateTime :date="file.images_sent"></DateTime>
                    </small>
                </router-link>

                <router-link tag="button" class="btn hide-md create-report"
                    :to="{ name: 'files.create_report', params: { id: file.id } }">
                    <i class="fas fa-file-invoice mr-2"></i>
                    {{ $t('files.create_report') }}
                </router-link>

                <router-link tag="button" class="btn hide-md share-file" :to="{ name: 'files.share.create' }">
                    <i class="fas fa-share-nodes mr-2"></i>
                    {{ $t('files.share') }}
                </router-link>

                <Dropdown class="dropdown-right">
                    <div class="menu-item send-images show-xl">
                        <router-link :to="{ name: 'files.send_images' }">
                            <span class="mr-2">{{ $t('files.send_by_email') }}</span>
                            <small v-if="file.images_sent" class="label label-success">
                                <DateTime :date="file.images_sent"></DateTime>
                            </small>
                        </router-link>
                    </div>
                    <div class="menu-item show-md create-report">
                        <router-link :to="{ name: 'files.create_report', params: { id: file.id } }">
                            {{ $t('files.create_report') }}
                        </router-link>
                    </div>
                    <div class="menu-item show-md share-file">
                        <router-link :to="{ name: 'files.share.create' }">{{ $t('files.share') }}</router-link>
                    </div>
                    <div class="menu-item edit">
                        <a href="#" @click.prevent="editReference()">{{ $t('files.edit_reference') }}</a>
                    </div>
                    <div class="menu-item create-snap">
                        <router-link :to="{ name: 'files.upload' }">{{ $t('files.upload') }}</router-link>
                    </div>
                    <div class="menu-item create-submit">
                        <router-link :to="{ name: 'files.submit_form' }">{{ $t('files.submit_form') }}</router-link>
                    </div>
                    <div class="menu-item download-images">
                        <router-link :to="{ name: 'files.download_zip' }">{{ $t('main.download') }}</router-link>
                    </div>
                    <div class="menu-item create-field">
                        <router-link :to="{ name: 'files.add_field', params: { id: file.id } }">
                            {{ $t('files.add_field') }}
                        </router-link>
                    </div>
                    <div class="menu-item view-history">
                        <router-link :to="{ name: 'files.history' }">{{ $t('files.history') }}</router-link>
                    </div>
                    <div class="menu-item create-issue">
                        <router-link :to="{ name: 'files.create_issue' }">{{ $t('context.create_issues')
                            }}</router-link>
                    </div>
                    <div class="menu-item close-file" v-if="!file.closed">
                        <a href="#" @click.prevent="closeFile()">{{ $t('files.close_files') }}</a>
                    </div>
                    <div class="menu-item open-file" v-else>
                        <a href="#" @click.prevent="openFile()">{{ $t('files.open_file') }}</a>
                    </div>
                    <div class="menu-item delete">
                        <a href="#" @click.prevent="deleteFile()" class="text-error">
                            {{ $t('files.delete_files') }}</a>
                    </div>
                </Dropdown>
            </template>
        </FileShow>

        <div v-else-if="fileNotFound" class="text-center">
            <img height="250" src="/images/snappy/uhooh2.png" alt />
            <div class="text-gray">{{ $t('main.not_found') }}</div>
        </div>

        <router-view v-if="file.id" :file="file" @refresh="fetchData()"></router-view>
    </div>
</template>

<script>
import FileShow from '~/components/files/FileShow.vue'

export default {
    components: { FileShow },

    data() {
        return {
            file: {},
            loading: false,
            fileNotFound: false,

            canEdit: this.$store.getters.can('Update files'),
            canOpen: this.$store.getters.can('Open files'),
            canClose: this.$store.getters.can('Close files'),
            canDelete: this.$store.getters.can('Delete files'),
            canSendImages: this.$store.getters.can('Use send email images from files'),

            canCreateSnap: this.$store.getters.can('Create snaps'),
            canCreateSubmit: this.$store.getters.can('Create form submits'),
            canEditSnaps: this.$store.getters.can('Update snaps'),
            canEditSubmits: this.$store.getters.can('Update form submits'),
            canDeleteSnaps: this.$store.getters.can('Delete snaps'),
            canDeleteSubmits: this.$store.getters.can('Delete form submits'),

            canShareFile: this.$store.getters.can('Create file shares'),
            canViewHistory: this.$store.getters.can('Read file history'),
            canCreateField: this.$store.getters.can('Create file tags'),
            canCreateReport: this.$store.getters.can('Create reports'),
            canDownloadImages: this.$store.getters.can('Download images as zip'),

            canCreateIssues: this.$store.getters.hasIssueManagement
        }
    },

    mounted() {
        this.fetchData()
    },

    watch: { '$route.params.id': 'fetchData' },

    methods: {
        async fetchData() {
            this.loading = true
            const fileId = this.$route.params.id

            try {
                const { data: file } = await this.$axios.get(`files/${fileId}`)

                file.timeline = file.uploads.concat(file.form_submits)

                this.file = file
            } catch {
                this.fileNotFound = true
            }

            this.loading = false
        },

        editReference() {
            if (!this.canEdit) return

            this.$swal({
                input: 'text',
                showCancelButton: true,
                showLoaderOnConfirm: true,
                confirmButtonColor: '#0092c8',
                inputValue: this.file.scan_code,
                title: this.$t('files.edit_reference'),
                confirmButtonText: this.$t('main.update'),
                cancelButtonText: this.$t('main.cancel'),
                preConfirm: async value => {
                    try {
                        await this.$axios.patch(`files/${this.file.id}`, { new_scan_code: value })
                        this.file.scan_code = value
                    } catch ({ response }) {
                        return response.data
                    }
                },
            }).then(result => {
                if (result.value && result.value.id) {
                    this.askMergeFiles(result.value.id, result.value.scan_code)
                }
            })
        },

        askMergeFiles(new_file_id, new_scan_code) {
            const textReferenceMerge = this.$t('files.reference_merge', {
                current: this.file.scan_code,
                new: new_scan_code,
            })

            this.$swal({
                text: textReferenceMerge,
                showCancelButton: true,
                confirmButtonColor: '#0092c8',
                title: this.$t('files.reference_exists'),
                confirmButtonText: this.$t('files.yes_merge_files'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (!result.value) return

                this.$axios.post(`files/${this.file.id}/merge/${new_file_id}`).then(({ data }) => {
                    this.$router.push({ name: 'files.show', params: { id: data.id } })
                })
            })
        },

        openFile() {
            if (!this.canOpen) return

            this.$swal({
                showCancelButton: true,
                confirmButtonColor: '#e89543',
                title: this.$t('files.open_file'),
                text: this.$t('files.open_warning'),
                cancelButtonText: this.$t('main.cancel'),
                confirmButtonText: this.$t('files.open_file'),
                preConfirm: async () => {
                    try {
                        return await this.$axios.patch(`files/${this.file.id}/open`)
                    } catch ({ response }) {
                        this.$swal.showValidationMessage(response.data.file)
                    }
                },
            }).then(result => {
                if (!result.value) return

                this.file.closed = false
                this.file.closed_at = null
                window.toast(this.$root.$t('files.open'))
            })
        },

        closeFile() {
            if (!this.canClose) return

            this.$swal({
                showCancelButton: true,
                confirmButtonColor: '#e89543',
                title: this.$t('files.close_files'),
                text: this.$t('files.close_warning'),
                cancelButtonText: this.$t('main.cancel'),
                confirmButtonText: this.$t('files.close_files'),
            }).then(result => {
                if (!result.value) return

                return this.$axios.patch(`files/${this.file.id}/close`).then(({ data }) => {
                    this.file.closed = true
                    this.file.closed_at = data[0].closed_at
                    window.toast(this.$root.$t('files.closed_file'))
                })
            })
        },

        deleteFile() {
            if (!this.canDelete) return

            this.$swal({
                showCancelButton: true,
                title: this.$t('main.are_you_sure'),
                text: this.$t('main.you_wont_be_able_to_revert_this'),
                confirmButtonText: this.$t('main.yes_delete_it'),
                cancelButtonText: this.$t('main.cancel'),
            }).then(result => {
                if (!result.value) return

                this.$axios.delete(`files/${this.file.id}`).then(() => {
                    this.$router.push({ name: 'files' })
                    window.toast(this.$root.$t('i.delete', { i: this.file.scan_code }))
                })
            })
        },
    },
}
</script>
