<template>
    <div>
        <ContextBar :title="$t('main.companies')">
            <DebounceInput v-model="search" clearable searchIcon />
        </ContextBar>

        <div class="flex-start">
            <label v-for="status in statusesList" :key="status.name" class="form-checkbox mr-2">
                <input type="checkbox" v-model="status.checked" @change="updateFilters()" />
                <i class="form-icon"></i>
                <span>{{ status.title }}</span>
            </label>
        </div>

        <div class="flex-start">
            <label v-for="region in regionsList" :key="region.name" class="form-checkbox mr-2">
                <input type="checkbox" v-model="region.checked" @change="updateFilters()" />
                <i class="form-icon"></i>
                <span>{{ region.title }}</span>
            </label>
        </div>

        <DataTable v-if="regionsList.length" endpoint="admin/tenants" :columns="columns" :parameters="parameters"
            :ignoreUrlParameters="['regions', 'statuses']" :key="refresh">
            <template #column(id)="{ value }">{{ value }}</template>
            <template #column(company)="{ item }">
                <div class="flex-start">
                    <router-link :to="{ name: 'companies.show', params: { id: item.id } }" class="table-link">
                        {{ item.company }}
                    </router-link>
                    <i v-if="item.soft_blocked" class="fas fa-ban text-error ml-2"></i>
                </div>
            </template>
            <template #column(domain)="{ item }">
                <a :class="{ ghost: !item.crm_id }" target="_blank"
                    :href="`https://app-eu1.hubspot.com/contacts/25965942/company/${item.crm_id}`">
                    <i class="fab fa-hubspot"></i>
                </a>
                <a class="mx-2" :href="'https://' + item.domain" target="_blank">
                    <i class="fas fa-external-link-alt"></i>
                </a>
                <span>{{ item.domain }}</span>
            </template>
            <template #column(status)="{ item }">
                <span class="text-capitalize mr-2">{{ item.status }}</span>
                <div v-if="item.trial_expired" class="label text-tiny">Trial expired</div>
                <span v-if="item.abowire_subscription_id" class="tooltip tooltip-right"
                    data-tooltip="Customer has a paid subscription">
                    <i class="fas fa-check text-gray"></i>
                </span>
            </template>
            <template #column(region)="{ value }">
                <span class="text-capitalize">{{ value }}</span>
            </template>
            <template #column(created_at)="{ value }">
                <DateTime :date="value"></DateTime>
            </template>
            <template #column(statistics)="{ item }">
                <router-link class="btn" :to="{ name: 'companies.stats', params: { id: item.id } }">
                    <i class="fas fa-chart-simple"></i>
                </router-link>
            </template>
        </DataTable>

        <router-view :regions="regionsList" :statuses="statusesList" @refresh="refresh++"></router-view>
    </div>
</template>

<script>
import DataTable from '~/components/DataTable.vue'

export default {
    components: { DataTable },

    data() {
        return {
            refresh: 0,
            search: this.$route.query.name,

            columns: [
                { name: 'id', th: 'ID', sortable: true },
                { name: 'company', th: 'Name', sortable: true },
                { name: 'domain', th: 'Domain', sortable: true },
                { name: 'status', th: 'Status', sortable: true },
                { name: 'region', th: 'Region', sortable: true },
                { name: 'created_at', th: 'Created', sortable: true },
                { name: 'statistics', th: 'Statistics' },
            ],

            regionsList: [],
            statusesList: [
                { name: 'standard', title: 'Standard', selectable: false },
                { name: 'enterprise', title: 'Enterprise', selectable: false },
                { name: 'trial', title: 'Trial', selectable: true },
                { name: 'free', title: 'Free', selectable: true },
                { name: 'deleted', title: 'Deleted', selectable: true },
                { name: 'sandbox', title: 'Sandbox', selectable: true },
            ],

            regions: this.$store.state.companyRegionsChecked,
            statuses: this.$store.state.companyStatusesChecked,
        }
    },

    computed: {
        parameters() {
            return {
                name: this.search || undefined,
                regions: this.regions || undefined,
                statuses: this.statuses || undefined,
            }
        },
    },

    async mounted() {
        const { data: regions } = await this.$axios.get('/admin/regions')

        this.regionsList = regions
        this.regionsList.forEach(i => i.checked = this.regions.includes(i.name))
        this.statusesList.forEach(i => i.checked = this.statuses.includes(i.name))
    },

    methods: {
        updateFilters() {
            this.regions = this.regionsList.filter(region => region.checked).map(region => region.name)
            this.statuses = this.statusesList.filter(status => status.checked).map(status => status.name)

            this.$store.commit('UPDATE_COMPANIES_REGIONS_CHECKED', this.regions)
            this.$store.commit('UPDATE_COMPANIES_STATUSES_CHECKED', this.statuses)
        },
    },
}
</script>